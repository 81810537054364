<template>
  <LoginContainer>
    <el-form
        @submit.native.prevent="submitForm"
        class="login-form"
    >
      <div class="login-container--header">
        {{ $t('system.create_new_password') }}
        <lang-select
            @changeLanguage="changeLanguage"
            class="login-lang-select"
        ></lang-select>
      </div>
      <div class="title-container">
        <img
            :src="logoImage"
            class="center-block logo"
            v-if="logoImage"
        >
        <h3
            class="title"
            v-else
        >{{ $t('login.title') }}</h3>
      </div>
      <el-form-item required>
        <el-input
            :class="checkinputRequired('password') ? 'validate-error' : ''"
            :placeholder="$t('system.password')"
            auto-complete="off"
            name="password"
            type="password"
            v-model="password"
        >
          <i slot="prefix">
            <font-awesome-icon icon="unlock" />
          </i>
        </el-input>
        <small
            class="help-block"
            v-if="backend_errors.password && !checkinputRequired('password')"
        >
          <span v-html="$t(backend_errors.password[0])"></span>
        </small>
      </el-form-item>
      <el-form-item required>
        <el-input
            :class="checkinputRequired('checkPass') ? 'validate-error' : ''"
            :placeholder="$t('system.checkPass')"
            auto-complete="off"
            name="checkPass"
            type="password"
            v-model="checkPass"
        >
          <i slot="prefix">
            <font-awesome-icon icon="unlock" />
          </i>
        </el-input>
        <small
            class="help-block"
            v-if="backend_errors.password_confirmation && !checkinputRequired('checkPass')"
        >
          <span v-html="$t(backend_errors.password_confirmation[0])"></span>
        </small>
      </el-form-item>
      <el-form-item class="d-flex justify-content-end">
        <el-button
            class="auth--buttonPrimary"
            native-type="submit"
            type="primary"
        >
          {{ $t('system.reset') }}
        </el-button>
      </el-form-item>
    </el-form>
  </LoginContainer>
</template>

<script>
import axios from 'axios';
import LangSelect from '../../components/LangSelect/index.vue';
import notification from '../../notification/notify';
import LoginContainer from './LoginContainer.vue';

export default {
  name: 'reset-password',
  data() {
    return {
      password: '',
      checkPass: '',
      backend_errors: {},
      inputRequiredEmpty: [],
    };
  },
  components: {LoginContainer, LangSelect},
  computed: {
    logoImage() {
      return this.$store.getters.logo;
    },
  },
  methods: {
    submitForm() {
      let vm = this;
      vm.checkValidation().then(function () {
        axios.post('password/reset', {
          email: vm.$route.params.email,
          password: vm.password,
          password_confirmation: vm.checkPass,
          token: vm.$route.params.token,
        }).then(() => {
          notification.notify(
              vm.$t('notify.success'),
              vm.$t('message.passwords.reset'),
              'success');
          setTimeout(function () {
            vm.$router.push('/login');
          }, 3000);
        }).catch(function (error) {
          if (error.response.data.type && error.response.data.type === 'error') {
            notification.notify(
                vm.$t('notify.error'),
                vm.$t(error.response.data.message),
                'error');
          } else {
            vm.backend_errors = error.response.data.errors;
          }
        });
      }).catch(function () {
        //
      });
    },
    changeLanguage(lang) {
      window.localStorage.setItem('langGlobal', lang);
      this.changeLanguageMixin(lang).then(() => {
        document.title = this.$t('system.create_new_password');
      });
    },
  },
};
</script>
